import { useSelector } from 'react-redux';
import { formatDate, getOffsetDays, getAppendMerchantTimezoneToDate, FRIENDLY_DATE_TIME_FORMAT } from 'utils/timing';

export function useHidePriceDatesStore() {
    return useSelector((state) => state.price?.hidePriceDates);
}

export function useHidePriceDates() {
    return useSelector((state) => state.price?.hidePriceDates?.filter((date) => !date?.isDeleted));
}

export function useIsFetchingHidePriceDates() {
    return useSelector((state) => state.price?.isFetchingHidePriceDates);
}

export function useHidePriceDatesForTable() {
    return useSelector((state) => {
        return state.price?.hidePriceDates?.map((date) => ({
            ...date,
            ranges: `${formatDate(
                new Date(getAppendMerchantTimezoneToDate(date.startDate)),
                FRIENDLY_DATE_TIME_FORMAT
            )} - ${formatDate(new Date(getAppendMerchantTimezoneToDate(date.endDate)), FRIENDLY_DATE_TIME_FORMAT)}`,
            numOfDays: getOffsetDays({ day1: date.endDate, day2: date.startDate }) + 1
        }));
    });
}
