import { useSelector } from 'react-redux';
import { isNotContainAllCheckingArray } from 'utils/object';
import { titleCase } from 'utils/naming';
import orderBy from 'lodash/orderBy';

export function useBookingIsInitializing() {
    return useSelector((state) => state.checkIn?.isInitializing);
}

export function useBookingCategories() {
    return useSelector((state) => state.checkIn?.categories);
}

export function useBookingStaffs() {
    return useSelector((state) => state.checkIn?.staffs);
}

export function useIsFetchingTimeSlots() {
    return useSelector((state) => state.checkIn?.isFetchingTimeSlots);
}

export function useTimeSlots() {
    return useSelector((state) => state.checkIn?.timeSlots);
}

export function useInComingBookings() {
    return useSelector((state) => state.checkIn?.inComingBookings);
}

export function useIsConflicted() {
    return useSelector((state) => state.checkIn?.isConflicted);
}

export function useBookingStaffsFilteredServices() {
    const staffs = useBookingStaffs();
    const selectedServices = useSelectedServices();

    const serviceIds = selectedServices?.map((service) => +service.id) || [];

    const staffFiltered = staffs?.filter((staff) => {
        return !isNotContainAllCheckingArray({ mainArr: staff?.staffServices || [], checkArr: serviceIds });
    });

    return orderBy(staffFiltered, ['orderIndex'], ['desc']);
}

export function useBookingOrder() {
    return useSelector((state) => state.checkIn?.order);
}

export function useSelectedServices() {
    const bookingOrder = useBookingOrder();
    return bookingOrder?.services || [];
}

export function useSelectedStaff() {
    const bookingOrder = useBookingOrder();
    return bookingOrder?.staff;
}

export function useSelectedDate() {
    const bookingOrder = useBookingOrder();
    return bookingOrder?.date;
}

export function useSelectedDateIndex() {
    const bookingOrder = useBookingOrder();
    return bookingOrder?.dateIndex;
}

export function useSelectedCategoryIndex() {
    const bookingOrder = useBookingOrder();
    return bookingOrder?.categoryIndex;
}

export function useSelectedTime() {
    const bookingOrder = useBookingOrder();
    return bookingOrder?.time;
}

export function useCurrentStep() {
    const bookingOrder = useBookingOrder();
    return bookingOrder?.step || 1;
}

export function useDateSlideOptions() {
    const bookingOrder = useBookingOrder();
    return bookingOrder?.dateSlideOptions || [];
}

export function useMerchantInfo() {
    const info = useSelector((state) => state.checkIn?.merchant);

    return {
        ...info,
        title: titleCase(info?.title)
    };
}

export function useIsMerchantHasPermission(permission) {
    const merchant = useMerchantInfo();
    return merchant?.permissions?.includes(permission);
}

export function useStaffDurationMap(staffId) {
    const staffs = useBookingStaffs();

    if (!staffId) return {};

    const foundStaff = staffs?.find((staff) => +staff?.id === +staffId);
    return !foundStaff ? {} : foundStaff?.serviceDuration;
}
