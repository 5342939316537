import request from './request';
import isEmpty from 'lodash/isEmpty';
import { PAGINATION_PAGE_DEFAULT, PAGINATION_LIMIT_DEFAULT } from 'const';
import { encodeURL, objectToQueryParams } from 'utils/queryParams';
import { downloadFileFromResponse } from 'utils/file';

const bookingApis = {
    bookings: `/bookings`,
    printBooking: (bookingId) => `/bookings/${bookingId}/print`,
    bookingClients: `/bookings/clients`,
    import: (merchantId) => `/bookings/import/${merchantId}`,
    export: `/bookings/export/booking-services`
};

export async function getBookingsApi({
    query,
    page = PAGINATION_PAGE_DEFAULT,
    limit = PAGINATION_LIMIT_DEFAULT,
    isPhoneNotNull = false
}) {
    let extraUrl = `${bookingApis.bookings}?page=${page}&limit=${limit}`;
    if (!isEmpty(query)) {
        extraUrl += `&query=${encodeURL(query)}`;
    }
    const response = await request.get(extraUrl);
    return response?.data;
}

export async function printBookingApi({ bookingId }) {
    const response = await request.post(bookingApis?.printBooking(bookingId));
    return response?.data;
}

export async function fetchClientByBookingRangeDatesApi(query) {
    let extraUrl = bookingApis.bookingClients;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function importBooking({ file, merchantId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(bookingApis.import(merchantId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function exportBookingServicesApi(query) {
    let extraUrl = bookingApis.export;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl, {
        responseType: 'blob'
    });
    downloadFileFromResponse(response);
}

export async function findBookingLogsApi(bkId) {
    const response = await request.get(`${bookingApis?.bookings}/${bkId}/logs`);
    return response?.data;
}
