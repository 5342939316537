import request from './request';

const apis = {
    hidePriceDates: '/hide-price-dates',
    hidePriceDate: (id) => `/hide-price-dates/${id}`
};

export async function getHidePriceDatesApi() {
    const response = await request.get(apis.hidePriceDates);
    return response.data;
}

export async function createHidePriceDateApi({ body }) {
    const response = await request.post(apis.hidePriceDates, body);
    return response.data;
}

export async function deleteHidePriceDateApi({ id }) {
    const response = await request.delete(apis.hidePriceDate(id));
    return response.data;
}

export async function updateHidePriceDateApi({ id, body }) {
    const response = await request.put(apis.hidePriceDate(id), body);
    return response.data;
}
