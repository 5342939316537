import * as types from './types';
import { getMerchantTimeSlotsApi } from 'services/merchant';
import { enqueueSnackbar } from 'notifier/actions';
import { convertTimeToFloat, getUtcISODateTime, TIMEZONE, getNextCurrentSlot, getDate } from 'utils/timing';
import { checkInBookingApi } from 'services/calendar';
import { BOOKING_ACTIONS, BOOKING_TYPES } from 'const';
import { cleanEmptyFieldsInObj } from 'utils/object';
import { getPublicStaffsApi } from 'services/staff';
import { getPublicCategoriesApi } from 'services/category';

export function fetchCheckInData({ slug, error, success }) {
    return async function (dispatch) {
        try {
            const [staffs, categories] = await Promise.all([getPublicStaffsApi(slug), getPublicCategoriesApi(slug)]);
            console.log('staffs', staffs);
            console.log('categories', categories);
            dispatch(_fetchCheckInData({ staffs, categories }));
            success && success();
        } catch ({ message }) {
            error && error(message);
        }
    };
}

function _fetchCheckInData({ staffs, categories }) {
    return {
        type: types.FETCH_CHECKING_DATA_SUCCESS,
        payload: {
            staffs,
            categories
        }
    };
}

export function initialBookingsActionSuccess({ categories, merchant }) {
    return {
        type: types.INITIAL_BOOKING_SUCCESS,
        payload: {
            categories,
            merchant
        }
    };
}

export function addService({ service }) {
    return {
        type: types.ADD_SERVICE,
        payload: {
            service
        }
    };
}

export function addStaff({ staff }) {
    return {
        type: types.ADD_STAFF,
        payload: {
            staff
        }
    };
}

export function addDate({ date, dateIndex }) {
    return {
        type: types.ADD_DATE,
        payload: {
            date,
            dateIndex
        }
    };
}

export function addTime({ time }) {
    return {
        type: types.ADD_TIME,
        payload: {
            time
        }
    };
}

export function goBackToChoosingStaff() {
    return {
        type: types.GO_BACK_TO_CHOOSING_STAFF
    };
}

export function goNextStep() {
    return {
        type: types.GO_NEXT_STEP
    };
}

export function goPrevStep() {
    return {
        type: types.GO_PREV_STEP
    };
}

export function setCurrentDateIndex(index) {
    return {
        type: types.SET_CURRENT_DATE_INDEX,
        payload: {
            index
        }
    };
}

export function setCurrentCategoryIndex(index) {
    return {
        type: types.SET_CURRENT_CATEGORY_INDEX,
        payload: {
            index
        }
    };
}

export function fetchTimeSlots({ slug, query, successCallback, errorCallback }) {
    return async function (dispatch) {
        dispatch(_fetchTimeSlotsAction());
        try {
            const response = await getMerchantTimeSlotsApi({
                query: {
                    ...query
                },
                slug
            });
            dispatch(_fetchTimeSlotsActionSuccess({ timeSlots: response.data }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `${message}`,
                    type: 'error'
                })
            );
            dispatch(_fetchTimeSlotsActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchTimeSlotsAction() {
    return {
        type: types.FETCH_TIME_SLOTS
    };
}

function _fetchTimeSlotsActionFailed() {
    return {
        type: types.FETCH_TIME_SLOTS_FAILED
    };
}

function _fetchTimeSlotsActionSuccess({ timeSlots = [] }) {
    return {
        type: types.FETCH_TIME_SLOTS_SUCCESS,
        payload: {
            timeSlots
        }
    };
}

export function verifyOTP({ slug, phone, title, note, email, successCallback, errorCallback, conflictCallback }) {
    return async function (dispatch, getState) {
        const { checkIn, merchant } = getState();
        const order = checkIn?.order;
        const services = order?.services || [];
        const staff = order?.staff;
        let servicesBody = [];
        const tz = merchant?.timezone || TIMEZONE;
        const slotInterval = merchant?.slotInterval || 15;

        if (!staff?.isSystem) {
            services.forEach((sv) => {
                servicesBody = [...servicesBody, { serviceId: +sv?.id, staffId: +staff?.id, love: true }];
            });
        } else {
            services.forEach((sv) => {
                servicesBody = [...servicesBody, { serviceId: +sv?.id }];
            });
        }

        const time = getNextCurrentSlot(slotInterval);

        const startDate = getUtcISODateTime(getDate(new Date(), tz), time, tz);

        const emailLowerCase = email?.toLowerCase();

        let body = {
            bookingServices: servicesBody,
            phone,
            title,
            startTime: convertTimeToFloat(time),
            startDate,
            note,
            email: emailLowerCase,
            bookingType: BOOKING_TYPES.ONLINE,
            state: BOOKING_ACTIONS.WAIT
        };

        if (staff?.isSystem) {
            body = {
                ...body,
                bookingType: BOOKING_TYPES.NO_REFERENCE
            };
        }

        dispatch(_bookingVerifyOTPAction());

        try {
            const booking = await checkInBookingApi({
                body: cleanEmptyFieldsInObj(body)
            });

            if (booking?.isConflicted) {
                dispatch(_setBookingConflicted(true));
            }

            dispatch(_bookingOrderActionSuccess());
            // dispatch(_bookingVerifyOTPActionSuccess({ token: response?.token }));
            successCallback && successCallback();
        } catch (error) {
            const { message, statusCode } = error;
            if (statusCode === 406) {
                return conflictCallback && conflictCallback(message);
            }
            dispatch(
                enqueueSnackbar({
                    message,
                    type: 'error'
                })
            );
            dispatch(_bookingVerifyOTPActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _setBookingConflicted(conflicted) {
    return {
        type: types.SET_BOOKING_CONFLICTED,
        payload: {
            conflicted
        }
    };
}

function _bookingVerifyOTPAction() {
    return {
        type: types.BOOKING_VERIFY_OTP
    };
}

function _bookingVerifyOTPActionFailed() {
    return {
        type: types.BOOKING_VERIFY_OTP_FAILED
    };
}

function _bookingOrderActionSuccess() {
    return {
        type: types.BOOKING_ORDER_SUCCESS
    };
}

export function resetOrder() {
    return {
        type: types.RESET_ORDER
    };
}
