import { STAGING, PRODUCTION, DEVELOPMENT, LOCAL } from './env';
const env = process.env.REACT_APP_ENVIRONMENT || LOCAL;

const apiUrls = {
    [PRODUCTION]: {
        baseUrl: 'https://api.booknailsalon.ca',
        clientUrl: `https://www.booksalon.ca`,
        title: 'BNS'
    },
    [DEVELOPMENT]: {
        baseUrl: 'https://api-dev.booknailsalon.ca',
        clientUrl: `https://demo-client.booknailsalon.ca`,
        title: 'BNS Development'
    },
    [LOCAL]: {
        baseUrl: 'http://localhost:3030',
        clientUrl: `http://localhost:3030`,
        title: 'BNS Development'
    },
    [STAGING]: {
        baseUrl: 'https://api-dev.booknailsalon.ca',
        clientUrl: `https://demo-client.booknailsalon.ca`,
        title: 'BNS Staging'
    }
};

export const ENV = env;
export const envConfig = apiUrls[env];
export const API_URL = apiUrls[env]['baseUrl'];
export const CLIENT_URL = apiUrls[env]['clientUrl'];
export const STAFF_AVATAR_PREFIX = `${API_URL}/uploads`;

export const FILE_STACK_API = 'AWumO5BVlRiu8dvUtKW0hz';
