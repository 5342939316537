import React from 'react';
import { makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import { Grid, Typography, CircularProgress, Hidden, Button, IconButton } from '@material-ui/core';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import InputBase from 'components/input/Form';
import { useTranslation } from 'react-i18next';
import { generateTempId, isTempId } from 'utils/uuid';
import { useStaffsServiceDuration } from 'hooks/staff';
import { useServiceOptions, useServicesMapping } from 'hooks/service';
import {
    getDatetimeFormat,
    convertTimeToFloat,
    convertFloatingToDateTime,
    convertDurationAndStartTimeToDate,
    mapTimeToTime12,
    convertDurationToHrsMins
} from 'utils/timing';
import moment from 'moment-timezone';
import * as calendarActions from 'views/calender/actions';
import * as bookingActions from 'views/booking/actions';
import { useDispatch } from 'react-redux';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import PortalDatePicker from 'components/datepicker/PortalMaterialDatePicker';
import { addPlus, isValidPhone, removePlus } from 'utils/phone';
// import SimpleBar from 'simplebar-react';
import DialogContent from 'components/dialog/Content';
import DialogTitle from 'components/dialog/Title';
import DialogActions from 'components/dialog/Action';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import NoShowIcon from '@material-ui/icons/EventBusy';
import ArrivedIcon from '@material-ui/icons/EventAvailable';
import RescheduleIcon from '@material-ui/icons/Repeat';
import RebookIcon from '@material-ui/icons/FlipToFront';
import InfoIcon from '@material-ui/icons/Info';
import DoneIcon from '@material-ui/icons/Done';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import StyledButton from 'components/button/Base';
import { BOOKING_ACTIONS, BOOKING_STATES, DEFAULT_DURATION, CONFIRM_TYPES, MERCHANT_PERMISSIONS } from 'const';
import NotFoundSVG from 'assets/images/svg/NotFoundSVG';
import { useClientByClientId } from 'hooks/client';
import { getFullName } from 'utils/naming';
import hexToRgba from 'hex-to-rgba';
import ConfirmDialog from 'components/dialog/Confirm';
import { useIsHasReviewButNoInternal, useIsHavingPermission } from 'hooks/auth';
import BookingService from './BookingService';
import { useMerchantCurrency, useMerchantTime12 } from 'hooks/merchant';
import Tooltip from 'components/tooltip';
import { timeOptions } from 'data/time';
import { useSelectedDate } from 'hooks/calendar';
import Checkbox from '@material-ui/core/Checkbox';
import ClientInput from './ClientInput';
import FetchingData from './FetchingData';
import BookingLogs from './BookingLogs';
import ClientLogs from './ClientLog';
import { enqueueSnackbar } from 'notifier/actions';

const FOOTER_HEIGHT = 80;
const MAX_HEIGHT = 632;

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 400,
        position: 'relative',
        borderRadius: 0,
        boxShadow: theme.shadows[1],
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: MAX_HEIGHT,
        maxHeight: MAX_HEIGHT
    },
    closeIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        // width: 30,
        // height: 30,
        cursor: 'pointer'
    },

    addMore: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    content: {
        height: `calc(${MAX_HEIGHT}px - ${FOOTER_HEIGHT}px - 40px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            height: `calc(100vh - ${FOOTER_HEIGHT}px - 40px)`,
            maxHeight: `calc(100vh - ${FOOTER_HEIGHT}px - 40px)`
        }
    },

    footer: {
        height: FOOTER_HEIGHT,
        borderTop: `1px solid ${theme.colors.border}`
    },
    wrapper: {
        position: 'relative'
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -6,
        marginLeft: -6
    },

    spacing: {
        paddingBottom: 12
    },
    padding: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    title: {
        height: 40,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 24,
        color: '#41415C',
        fontWeight: 400,
        fontStyle: 'normal',
        textTransform: 'capitalize'
    },
    fixedBottom: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        left: 0,
        background: theme.palette.common.white
    },
    contentPadding: {
        paddingBottom: FOOTER_HEIGHT
    },
    icon: {
        width: 30,
        height: 30
    },
    close: {
        '&:hover svg': {
            color: theme.palette.primary.main
        }
    },
    cancel: {
        '&:hover svg': {
            color: theme.colors.red
        }
    },
    confirm: {
        '&:hover svg': {
            color: theme.colors.turquoise
        }
    },
    reschedule: {
        '&:hover svg': {
            color: theme.colors.brightSun
        }
    },
    done: {
        '&:hover svg': {
            color: theme.colors.green
        }
    },
    save: {
        '&:hover svg': {
            color: `#8FCCFF`
        }
    },
    item: {
        cursor: 'pointer',
        position: 'relative'
    },
    disabled: {
        opacity: 0.4,
        pointerEvents: 'none'
    },

    dlFlex: {
        display: 'flex'
    },
    bookingPhone: {
        position: 'relative',
        top: -14
    },
    ellipsisWrapper: {
        maxWidth: '100%',
        overflow: 'hidden'
    },
    ellipsis: {
        display: `inline-block`,
        maxWidth: `100%`,
        whiteSpace: `nowrap`,
        textOverflow: `ellipsis`,
        overflow: `hidden`
    },
    services: {
        width: 400,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    client: {
        width: 500
    },
    scoreStatusPadding: {
        paddingTop: 14,
        paddingBottom: 14
    },
    scoreSection: {
        borderBottom: `1px solid ${theme.colors.border}`
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: hexToRgba(theme.palette.common.white, 0.7),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    expand: {
        flex: 1
    },
    whiteColor: {
        color: theme.palette.common.white
    },

    bookingServiceItem: {
        background: theme.colors.hover,
        borderRadius: theme.radius(3),
        marginBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    tableCell: {
        border: 'none',
        lineHeight: `1.5rem`,
        fontSize: `0.875rem`,
        fontFamily: `"Public Sans", sans-serif`,
        fontWeight: 600,
        display: `table-cell`,
        verticalAlign: 'inherit',
        textAlign: 'left',
        padding: '16px',
        borderBottom: 'none',
        color: `rgb(99, 115, 129)`,
        backgroundColor: theme.colors.hover,
        '&:first-of-type': {
            paddingLeft: '24px',
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            boxShadow: `rgb(255 255 255) 8px 0px 0px inset`
        },
        '&:last-of-type': {
            paddingLeft: '24px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            boxShadow: `rgb(255 255 255) -8px 0px 0px inset`
        }
    },
    newClientWarning: {
        borderRadius: theme.radius(3),
        background: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.common.white,
        padding: theme.spacing(1),
        width: '100%',
        border: `1px solid ${theme.palette.primary.main}`,
        marginBottom: theme.spacing(2)
    }
}));

const BookingSchema = object({
    title: string().required('This filed is required'),
    phone: string(),
    note: string()
});

function getSelectedStartDate(booking) {
    if (!booking.startDate) {
        return new Date();
    }
    return moment(booking?.startDate);
}

function AddBooking({ staff, time, onClose, isEdit, booking, isDialog, onReSchedule, onReBooking }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const selectedDate = useSelectedDate();
    const TIME_12 = useMerchantTime12();
    const currency = useMerchantCurrency();

    const isNoShow = React.useMemo(() => {
        return booking?.state === BOOKING_STATES.NO_SHOW;
    }, [booking]);

    const servicesMapping = useServicesMapping();

    const [startDate, setStartDate] = React.useState(isEdit ? getSelectedStartDate(booking) : selectedDate);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isRefunding, setIsRefunding] = React.useState(false);
    const [isConfirmArrived, setIsConfirmingArrived] = React.useState(false);
    const [isConfirmNoShow, setIsConfirmNoShow] = React.useState(false);
    const [clientId, setClientId] = React.useState(isEdit ? +booking?.clientId : null);
    const client = useClientByClientId(clientId);

    const bookingId = React.useMemo(() => {
        return booking?.id;
    }, [booking]);

    const serviceOptions = useServiceOptions();
    const staffsServiceDuration = useStaffsServiceDuration();
    const dispatch = useDispatch();
    const isInternalBasic = useIsHavingPermission(MERCHANT_PERMISSIONS.INTERNAL_BASIC);
    const isInternal = useIsHavingPermission(MERCHANT_PERMISSIONS.INTERNAL);
    const needConfirmPopup = useIsHavingPermission(MERCHANT_PERMISSIONS.UPDATE_BOOKING_CONFIRMED);
    const isHasReviewButNoInternal = useIsHasReviewButNoInternal();
    const [isShowConfirmCancel, setIsShowConfirmCancel] = React.useState(false);
    const [isShowConfirmRefund, setIsShowConfirmRefund] = React.useState(false);
    const isHasStaffServiceDurationPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.STAFF_SERVICE_DURATION);
    const isHasDepositPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.DEPOSIT);
    const isHasDepositAmountPermission = useIsHavingPermission(MERCHANT_PERMISSIONS.DEPOSIT_AMOUNT);
    const isHasAllowSendManualConfirm = useIsHavingPermission(
        MERCHANT_PERMISSIONS.ALLOW_SEND_CONFIRMATION_MANUAL_BOOKING
    );
    const [includedRefund, setIncludedRefund] = React.useState(true);
    const [includedSendConfirmation, setIncludedSendConfirmation] = React.useState(false);

    const paidPrice = React.useMemo(() => {
        return booking?.bookingServices?.reduce((t, bkSv) => {
            return t + (bkSv?.paidPrice || 0);
        }, 0);
    }, [booking]);

    const isShowRefund = React.useMemo(() => {
        return (
            isEdit &&
            (isHasDepositPermission || isHasDepositAmountPermission) &&
            paidPrice !== 0 &&
            [BOOKING_STATES.NO_SHOW, BOOKING_STATES.CLIENT_CONFIRMED]?.includes(booking?.state)
        );
    }, [paidPrice, isEdit, isHasDepositPermission, isHasDepositAmountPermission, booking]);

    const primaryServiceId = React.useMemo(() => {
        return serviceOptions?.find((service) => service?.isPrimarySearch)?.id || serviceOptions?.[0]?.id;
    }, [serviceOptions]);

    const DEFAULT_SERVICE = React.useMemo(() => {
        const staffId = +staff?.id;

        const newServiceOptions = serviceOptions?.filter((service) => {
            const staffs = service?.serviceStaffs || [];
            const staffIds = staffs?.map((sv) => +sv?.staffId);
            return staffIds.includes(staffId) && !service?.category?.deleted;
        });

        const newPrimaryService = newServiceOptions?.find((service) => service?.isPrimarySearch);
        const defaultPrimaryService = serviceOptions?.find((service) => service?.isPrimarySearch);
        const firstService = newServiceOptions?.[0];

        const service = !newPrimaryService
            ? !firstService
                ? defaultPrimaryService || serviceOptions?.[0]
                : firstService
            : newPrimaryService;
        const serviceId = +service?.id;
        const defaultDuration = service?.prices?.[0]?.duration;

        const duration = isHasStaffServiceDurationPermission
            ? staffsServiceDuration?.[staffId]?.[serviceId] || defaultDuration
            : defaultDuration;

        if (isHasStaffServiceDurationPermission) {
            return {
                staffId: isEdit ? +booking?.bookingServices?.[0]?.staffId : staffId,
                id: generateTempId(),
                startTime: time,
                serviceId,
                duration
            };
        } else {
            return {
                staffId: isEdit ? +booking?.bookingServices?.[0]?.staffId : staffId,
                id: generateTempId(),
                startTime: time,
                serviceId
            };
        }
    }, [staff, isEdit, time, booking, staffsServiceDuration, serviceOptions, isHasStaffServiceDurationPermission]);

    const [serviceList, setServiceList] = React.useState(
        isEdit
            ? orderBy(
                  booking?.bookingServices?.map((bkSv) => {
                      if (!serviceOptions?.map((option) => +option?.id)?.includes(+bkSv?.serviceId)) {
                          bkSv.serviceId = primaryServiceId;
                      }
                      return {
                          ...bkSv,
                          startTime: convertFloatingToDateTime(bkSv.startTime)
                      };
                  }),
                  ['startTime', 'asc']
              )
            : [DEFAULT_SERVICE]
    );

    const formik = useFormik({
        initialValues: {
            title: isEdit ? booking.title : '' || '',
            note: isEdit ? (!booking.note ? '' : booking.note) : '' || '',
            phone: isEdit ? addPlus(booking?.client?.phone) : ''
        },
        validationSchema: BookingSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            setStatus({ error: null });

            if (!isEdit) {
                const bookingServices = serviceList
                    .filter((sv) => sv.serviceId && sv.staffId)
                    .map((sv) => {
                        const defaultDuration =
                            serviceOptions.find((svOpt) => +sv.serviceId === +svOpt.id)?.prices?.[0].duration ||
                            DEFAULT_DURATION;
                        return {
                            serviceId: +sv.serviceId,
                            staffId: +sv.staffId,
                            startTime: convertTimeToFloat(sv.startTime),
                            love: sv.love,
                            duration: isHasStaffServiceDurationPermission
                                ? staffsServiceDuration?.[sv?.staffId]?.[sv?.serviceId] || defaultDuration
                                : defaultDuration
                        };
                    });
                const startTimes = bookingServices?.map((sv) => sv?.startTime);
                const startTime = Math.min.apply(Math, startTimes);

                let createBody = {
                    title: values?.title,
                    note: values?.note,
                    startDate: moment(`${getDatetimeFormat(startDate)} ${convertFloatingToDateTime(startTime)}`)
                        .utc()
                        .format(),
                    state: BOOKING_ACTIONS.CLIENT_CONFIRMED,
                    startTime,
                    bookingServices,
                    includedSendConfirmation
                };

                if (values?.extraPhone) {
                    const _phone = `${values?.prefix}${values?.extraPhone}`;
                    if (!isValidPhone(_phone)) {
                        dispatch(
                            enqueueSnackbar({
                                message: `${_phone} is an invalid phone number`
                            })
                        );
                        setSubmitting(false);
                        return;
                    }

                    createBody = {
                        ...createBody,
                        phone: removePlus(_phone)
                    };
                }

                setSubmitting(true);

                console.log('createBody', createBody);
                // return;
                dispatch(
                    calendarActions.createBooking({
                        body: createBody,
                        successCallback: () => {
                            onClose && onClose();
                        },
                        errorCallback: () => {
                            console.log('create booking failed');
                            setSubmitting(false);
                        }
                    })
                );

                setStatus({ error: null });
            } else {
                if (needConfirmPopup) {
                    let confirm = window.confirm('Are you sure you want to update this booking?');
                    if (!confirm) {
                        setSubmitting(false);
                        return;
                    }
                }

                const oldLovedBookingServices = booking?.bookingServices?.filter((bkSv) => bkSv?.love);

                const bookingServices = serviceList
                    .filter((bkSv) => bkSv?.serviceId && bkSv?.staffId)
                    .map((bkSv) => {
                        if (bkSv.duration) {
                            let svBody = {
                                serviceId: +bkSv.serviceId,
                                staffId: +bkSv.staffId,
                                startTime: convertTimeToFloat(bkSv.startTime),
                                duration: bkSv.duration,
                                love: bkSv.love
                            };
                            if (bkSv?.id && !isTempId(bkSv?.id)) {
                                svBody.id = +bkSv?.id;
                                const oldLovedBkSv = oldLovedBookingServices?.find(
                                    (loveBkSv) => +loveBkSv?.id === +bkSv?.id
                                );
                                if (oldLovedBkSv && oldLovedBkSv?.staffId !== bkSv?.staffId) {
                                    svBody.love = false;
                                }

                                if (bkSv?.price) {
                                    svBody = {
                                        ...svBody,
                                        price: +bkSv?.price
                                    };
                                }
                            }
                            return svBody;
                        }

                        const defaultDuration =
                            serviceOptions.find((svOpt) => +bkSv.serviceId === +svOpt.id)?.prices?.[0].duration ||
                            DEFAULT_DURATION;

                        let svBody = {
                            serviceId: +bkSv.serviceId,
                            staffId: +bkSv.staffId,
                            startTime: convertTimeToFloat(bkSv.startTime),
                            love: bkSv.love,
                            duration: isHasStaffServiceDurationPermission
                                ? staffsServiceDuration?.[bkSv?.staffId]?.[bkSv?.serviceId] || defaultDuration
                                : defaultDuration
                        };
                        if (bkSv?.id && !isTempId(bkSv?.id)) {
                            svBody.id = +bkSv?.id;
                            const oldLovedBkSv = oldLovedBookingServices?.find(
                                (loveBkSv) => +loveBkSv?.id === +bkSv?.id
                            );
                            if (oldLovedBkSv && oldLovedBkSv?.staffId !== bkSv?.staffId) {
                                svBody.love = false;
                            }

                            if (bkSv?.price) {
                                svBody = {
                                    ...svBody,
                                    price: +bkSv?.price
                                };
                            }
                        }
                        return svBody;
                    });
                const startTimes = bookingServices?.map((sv) => sv?.startTime);
                const startTime = Math.min.apply(Math, startTimes);

                let editBody = {
                    ...values,
                    startDate: moment(`${getDatetimeFormat(startDate)} ${convertFloatingToDateTime(startTime)}`)
                        .utc()
                        .format(),
                    state: BOOKING_ACTIONS.CLIENT_CONFIRMED,
                    startTime,
                    bookingServices
                };

                if (values?.extraPhone) {
                    const _phone = `${values?.prefix}${values?.extraPhone}`;
                    if (!isValidPhone(_phone)) {
                        dispatch(
                            enqueueSnackbar({
                                message: `${_phone} is an invalid phone number`
                            })
                        );
                        setSubmitting(false);
                        return;
                    }

                    editBody = {
                        ...editBody,
                        phone: removePlus(_phone)
                    };
                }

                console.log('editBody', editBody);
                // return;
                setSubmitting(true);

                dispatch(
                    calendarActions.updateBooking({
                        bookingId: booking.id,
                        body: editBody,
                        oldBooking: booking,
                        successCallback: () => {
                            console.log('update booking sucsces');
                            onClose && onClose();
                        },
                        errorCallback: () => {
                            console.log('update booking failed');
                            setSubmitting(false);
                        }
                    })
                );

                setStatus({ error: null });
            }
        }
    });

    // const handleStartTimeChange = (e) => {
    //     setStartTime(e.target.value);
    // };

    const handleAddMoreService = React.useCallback(() => {
        const serviceListSorted = sortBy(serviceList, 'startTime');
        const lastItem = serviceListSorted?.[serviceListSorted?.length - 1];
        const serviceId = lastItem?.serviceId;
        const startTime = lastItem?.startTime || '00:00';
        const lastItemDuration = lastItem?.duration;
        const serviceDetail = serviceOptions.find((sv) => +sv?.id === +serviceId);
        const duration = lastItemDuration || serviceDetail?.prices?.[0]?.duration || DEFAULT_DURATION;
        const startTimeFloat = convertTimeToFloat(startTime);
        const nextStartTime = convertDurationAndStartTimeToDate(startTimeFloat, duration);

        setServiceList([...serviceList, { ...DEFAULT_SERVICE, id: generateTempId(), startTime: nextStartTime }]);
    }, [DEFAULT_SERVICE, serviceList, serviceOptions]);

    const handleRemoveService = React.useCallback(
        (id) => {
            setServiceList(serviceList.filter((sv) => sv.id !== id));
        },
        [serviceList]
    );

    const timeOptionsWithFormat = React.useMemo(() => {
        return TIME_12
            ? timeOptions.map((time) => {
                  return {
                      ...time,
                      label: mapTimeToTime12(time.value)
                  };
              })
            : timeOptions;
    }, [TIME_12]);

    const handleServiceChange = React.useCallback(
        ({ id, serviceId }) => {
            const newServiceList = serviceList.map((serviceOption) => {
                const staffId = serviceOption?.staffId;

                if (id === serviceOption?.id) {
                    if (+serviceOption.serviceId !== +serviceId) {
                        const duration =
                            serviceOptions.find((svOpt) => +serviceId === +svOpt.id)?.prices?.[0].duration ||
                            DEFAULT_DURATION;

                        serviceOption.duration = isHasStaffServiceDurationPermission
                            ? staffsServiceDuration?.[staffId]?.[serviceId] || duration
                            : duration;

                        serviceOption.price =
                            serviceOptions.find((svOpt) => +serviceId === +svOpt.id)?.prices?.[0].retailPrice || 0;
                    }

                    serviceOption.serviceId = serviceId;
                }
                return serviceOption;
            });
            setServiceList(newServiceList);
        },
        [serviceList, serviceOptions, staffsServiceDuration, isHasStaffServiceDurationPermission]
    );

    const handleStaffChange = React.useCallback(
        ({ id, staffId }) => {
            const newServiceList = serviceList.map((serviceOption) => {
                if (id === serviceOption.id) {
                    if (+serviceOption?.staffId !== +staffId && isHasStaffServiceDurationPermission) {
                        const serviceId = serviceOption?.serviceId;
                        const duration =
                            serviceOptions.find((svOpt) => +serviceId === +svOpt.id)?.prices?.[0].duration ||
                            DEFAULT_DURATION;

                        serviceOption.duration = isHasStaffServiceDurationPermission
                            ? staffsServiceDuration?.[staffId]?.[serviceId] || duration
                            : duration;
                    }

                    serviceOption.staffId = staffId;
                }
                return serviceOption;
            });

            setServiceList(newServiceList);
        },
        [serviceList, isHasStaffServiceDurationPermission, serviceOptions, staffsServiceDuration]
    );

    const handleLoveChange = React.useCallback(
        ({ id }) => {
            const newServiceList = serviceList.map((serviceOption) => {
                if (id === serviceOption.id) {
                    serviceOption.love = !serviceOption.love;
                }
                return serviceOption;
            });

            setServiceList(newServiceList);
        },
        [serviceList]
    );

    const handleCancelBooking = React.useCallback(() => {
        setIsShowConfirmCancel(true);
    }, []);

    const handleRefundBooking = React.useCallback(() => {
        setIsShowConfirmRefund(true);
    }, []);

    const handleRefundAgree = React.useCallback(() => {
        setIsRefunding(true);

        dispatch(
            calendarActions.refundBooking({
                bookingId: booking?.id,
                successCallback: () => {
                    setIsRefunding(false);
                    setIsShowConfirmRefund(false);
                },
                errorCallback: () => {
                    setIsRefunding(false);
                    console.log('update booking failed');
                }
            })
        );
    }, [booking, dispatch]);

    const handleCancelAgree = React.useCallback(() => {
        setIsDeleting(true);
        if (isShowRefund && includedRefund) {
            dispatch(
                calendarActions.refundBooking({
                    bookingId: booking?.id,
                    successCallback: () => {
                        dispatch(
                            calendarActions.cancelBooking({
                                bookingId: booking.id,
                                body: {
                                    state: BOOKING_ACTIONS.CLIENT_CANCEL
                                },
                                successCallback: () => {
                                    onClose && onClose();
                                },
                                errorCallback: () => {
                                    setIsDeleting(false);
                                    console.log('update booking failed');
                                }
                            })
                        );
                    },
                    errorCallback: () => {}
                })
            );
        } else {
            dispatch(
                calendarActions.cancelBooking({
                    bookingId: booking.id,
                    body: {
                        state: BOOKING_ACTIONS.CLIENT_CANCEL
                    },
                    successCallback: () => {
                        onClose && onClose();
                    },
                    errorCallback: () => {
                        setIsDeleting(false);
                        console.log('update booking failed');
                    }
                })
            );
        }
    }, [booking, dispatch, onClose, includedRefund, isShowRefund]);

    const handleMakeBookingDone = React.useCallback(() => {
        setIsDeleting(true);
        dispatch(
            calendarActions.updateBooking({
                bookingId: booking.id,
                body: {
                    state: BOOKING_ACTIONS.END
                },
                successCallback: () => {
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsDeleting(false);
                    console.log('update booking failed');
                }
            })
        );
    }, [booking, onClose, dispatch]);

    const { values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue } = formik;

    const isValid = React.useMemo(() => {
        return values?.title && serviceList?.filter((sv) => sv.serviceId && sv.staffId)?.length > 0;
    }, [values, serviceList]);

    const onDateChange = React.useCallback((date) => {
        setStartDate(date);
    }, []);

    const handleStartTimeChange = React.useCallback(
        ({ id, startTime }) => {
            const newServiceList = serviceList.map((serviceOption) => {
                if (id === serviceOption.id) {
                    serviceOption.startTime = startTime;
                }
                return serviceOption;
            });
            setServiceList(newServiceList);
        },
        [serviceList]
    );

    // const handlePhoneChange = React.useCallback(
    //     (value) => {
    //         setFieldValue('phone', value);
    //     },
    //     [setFieldValue]
    // );

    // const isCanDelete = React.useMemo(() => {
    //     return serviceList.length <= 1;
    // }, [serviceList]);

    const handleNoShowBooking = React.useCallback(() => {
        setIsConfirmNoShow(true);
        dispatch(
            calendarActions.updateBooking({
                bookingId: booking.id,
                body: {
                    state: BOOKING_ACTIONS.NO_SHOW
                },
                successCallback: () => {
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsConfirmNoShow(false);
                    console.log('handleNoShowBooking failed');
                }
            })
        );
    }, [booking, dispatch, onClose]);

    const handleConfirmArrived = React.useCallback(() => {
        setIsConfirmingArrived(true);
        dispatch(
            calendarActions.updateBooking({
                bookingId: booking.id,
                body: {
                    state: BOOKING_ACTIONS.CLIENT_ARRIVED
                },
                successCallback: () => {
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsConfirmingArrived(false);
                    console.log('handleConfirmArrived failed');
                }
            })
        );
    }, [booking, dispatch, onClose]);

    const handlePrintBooking = React.useCallback(() => {
        dispatch(
            bookingActions.printBooking({
                bookingId: booking.id,
                body: {
                    state: BOOKING_ACTIONS.CLIENT_ARRIVED
                },
                successCallback: () => {
                    onClose && onClose();
                },
                errorCallback: () => {
                    setIsConfirmingArrived(false);
                    console.log('handleConfirmArrived failed');
                }
            })
        );
    }, [dispatch, booking, onClose]);

    const handleClientChange = React.useCallback(
        (client) => {
            if (client) {
                setFieldValue('title', getFullName(client));
                setClientId(+client?.id);
                // setClientNote(client?.note);
                setFieldValue('phone', addPlus(client?.user?.phone));
            }
        },
        [setFieldValue]
    );

    const handlePhoneChange = React.useCallback(
        ({ prefix, extraPhone }) => {
            setFieldValue('extraPhone', extraPhone);
            setFieldValue('prefix', prefix);
        },
        [setFieldValue]
    );

    const totalBookingPrice = React.useMemo(() => {
        return serviceList?.reduce((total, bkSv) => {
            return total + (+bkSv?.price || 0) || +servicesMapping?.[bkSv?.serviceId]?.prices?.[0]?.retailPrice || 0;
        }, 0);
    }, [serviceList, servicesMapping]);

    const totalBookingDuration = React.useMemo(() => {
        return serviceList?.reduce((total, bkSv) => {
            return total + (+bkSv?.duration || 0) || +servicesMapping?.[bkSv?.serviceId]?.prices?.[0]?.duration || 0;
        }, 0);
    }, [serviceList, servicesMapping]);

    const cloneBkSv = React.useCallback(
        (cloneBkSv) => {
            const serviceId = cloneBkSv?.serviceId;
            const startTime = cloneBkSv?.startTime || timeOptionsWithFormat?.[0]?.value;
            const lastBkSvDuration = cloneBkSv?.duration;
            const serviceDetail = servicesMapping?.[serviceId];
            const duration = lastBkSvDuration || serviceDetail?.prices?.[0]?.duration || DEFAULT_DURATION;
            const startTimeFloat = convertTimeToFloat(startTime);
            const nextStartTime = convertDurationAndStartTimeToDate(startTimeFloat, duration);

            setServiceList([...serviceList, { ...cloneBkSv, id: generateTempId(), startTime: nextStartTime }]);
        },
        [serviceList, servicesMapping, timeOptionsWithFormat]
    );

    return (
        <form onSubmit={handleSubmit}>
            <IconButton size="small" onClick={onClose} className={classes.closeIcon}>
                <CloseIcon style={{ color: theme.palette.common.white }} />
            </IconButton>

            <DialogTitle>
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item>
                        <Typography className={classes.whiteColor} variant="h5">
                            {isEdit ? t(`update`) : t(`create`)} {t(`booking`)}
                        </Typography>
                    </Grid>
                    {isShowRefund && (
                        <Grid item>
                            <StyledButton
                                onClick={handleRefundBooking}
                                style={{
                                    background: theme.colors.sun
                                }}
                                variant="contained"
                                startIcon={<RestoreIcon />}
                                size="small"
                            >
                                Refund
                            </StyledButton>
                        </Grid>
                    )}
                </Grid>
            </DialogTitle>
            <DialogContent className={`${classes.content} ${classes.padding}`}>
                <>
                    {client && client?.client?.isNewClient && (
                        <Grid container direction="row" alignItems="center" className={classes.newClientWarning}>
                            <Grid item style={{ display: 'flex', marginRight: theme.spacing(1) }}>
                                <InfoIcon color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">This is new client. Please take good care!</Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        container
                        direction="row"
                        style={{
                            paddingLeft: isDialog ? 20 : '',
                            paddingRight: isDialog ? 20 : '',
                            height: '100%'
                        }}
                        spacing={2}
                        wrap="nowrap"
                    >
                        <Grid item className={classes.services}>
                            <Grid container direction="column" wrap="nowrap">
                                <Hidden smDown>
                                    <Grid item className={classes.spacing}>
                                        <InputBase
                                            fullWidth
                                            className={`${classes.input}`}
                                            label={t('client_name')}
                                            placeholder={t('booking_title_placeholder')}
                                            name="title"
                                            value={values.title || ''}
                                            disabled={isSubmitting || isNoShow}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={errors.title && touched.title}
                                            errorText={errors.title}
                                        />
                                    </Grid>
                                </Hidden>

                                <Hidden smUp>
                                    <Grid item className={classes.spacing}>
                                        <Grid container spacing={2} direction="row" alignItems="flex-end">
                                            <Grid item xs={5}>
                                                <InputBase
                                                    fullWidth
                                                    className={`${classes.input}`}
                                                    label={t('client_name')}
                                                    placeholder={t('booking_title_placeholder')}
                                                    name="title"
                                                    value={values.title || ''}
                                                    disabled={isSubmitting || isNoShow}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    error={errors.title && touched.title}
                                                    errorText={errors.title}
                                                />
                                            </Grid>
                                            <Grid item xs={7} className={classes.bookingPhone}>
                                                <ClientInput
                                                    isEdit={isEdit}
                                                    booking={booking}
                                                    onChange={handleClientChange}
                                                    onPhoneChange={handlePhoneChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Hidden>

                                <Grid item className={classes.spacing}>
                                    <PortalDatePicker
                                        onChange={onDateChange}
                                        defaultValue={startDate}
                                        label={t(`start_date`)}
                                    />
                                </Grid>

                                <Grid item className={classes.spacing}>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="body1">Services</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                className={classes.bookingServices}
                                                container
                                                direction="column"
                                                wrap="nowrap"
                                            >
                                                {serviceList.map((serviceOption, index) => {
                                                    return (
                                                        <Grid
                                                            item
                                                            key={serviceOption?.id}
                                                            className={classes.bookingServiceItem}
                                                        >
                                                            <Grid container direction="column" spacing={1}>
                                                                <Grid item>
                                                                    <Grid
                                                                        container
                                                                        alignItems="center"
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            style={{
                                                                                width: 20,
                                                                                height: 20,
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                background: theme.palette.primary.main,
                                                                                color: theme.palette.common.white,
                                                                                borderRadius: '50%'
                                                                            }}
                                                                        >
                                                                            {index + 1}
                                                                        </Grid>

                                                                        <Grid item>
                                                                            <Grid container direction="row" spacing={1}>
                                                                                <Grid item>
                                                                                    <Tooltip
                                                                                        placement="top"
                                                                                        title="Clone booking service"
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                cloneBkSv(serviceOption)
                                                                                            }
                                                                                            size="small"
                                                                                            color="primary"
                                                                                        >
                                                                                            <RebookIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Grid>
                                                                                {serviceList?.length > 1 && (
                                                                                    <Grid item>
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            onClick={() =>
                                                                                                handleRemoveService(
                                                                                                    serviceOption?.id
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <DeleteIcon
                                                                                                fontSize="small"
                                                                                                color="secondary"
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <BookingService
                                                                        {...serviceOption}
                                                                        handleStaffChange={handleStaffChange}
                                                                        handleServiceChange={handleServiceChange}
                                                                        onLoveChange={handleLoveChange}
                                                                        handleRemoveService={handleRemoveService}
                                                                        handleStartTimeChange={handleStartTimeChange}
                                                                        isNoShow={isNoShow}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                                {!isNoShow && (
                                                    <Grid item>
                                                        <Button
                                                            size="small"
                                                            onClick={handleAddMoreService}
                                                            style={{
                                                                textDecoration: 'underline',
                                                                textTransform: 'inherit'
                                                            }}
                                                            color="primary"
                                                            startIcon={<AddIcon />}
                                                        >
                                                            {t(`add_another_service`)}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item className={classes.spacing}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                            <Typography variant="body2">
                                                Total price:{' '}
                                                <strong style={{ color: theme.colors.blue }}>
                                                    {currency}
                                                    {totalBookingPrice}
                                                </strong>
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="body2">
                                                Duration:{' '}
                                                <strong style={{ color: theme.colors.blue }}>
                                                    {convertDurationToHrsMins(totalBookingDuration)}
                                                </strong>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <InputBase
                                        fullWidth
                                        className={`${classes.input}`}
                                        label={'Note'}
                                        placeholder={t('booking_note_placeholder')}
                                        name="note"
                                        value={values.note || ''}
                                        disabled={isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={errors.note && touched.note}
                                        errorText={errors.note}
                                        multiline
                                        maxRows={3}
                                    />
                                </Grid>
                                {isEdit && (
                                    <Grid item>
                                        <BookingLogs createdAt={booking?.createdAt} bookingId={bookingId} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                        <Hidden smDown>
                            <Grid item className={classes.client}>
                                <Grid container direction="column">
                                    <Grid item>
                                        {/* <PhoneInputWithClientSuggestion
                                            label={t('phone_number')}
                                            placeholder={`(471) 213-1312`}
                                            disabled={isSubmitting || isNoShow}
                                            onChange={handlePhoneChange}
                                            defaultValue={values.phone}
                                            onClientChange={handleClientChange}
                                            autoFocus={!isEdit}
                                        /> */}

                                        <ClientInput
                                            isEdit={isEdit}
                                            onPhoneChange={handlePhoneChange}
                                            booking={booking}
                                            onChange={handleClientChange}
                                        />
                                    </Grid>
                                    {!client && (
                                        <Grid item style={{ marginTop: 100 }}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="center"
                                                direction="column"
                                                spacing={2}
                                            >
                                                <Grid item>
                                                    <Typography variant="body2">No client found</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <NotFoundSVG width={80} height={80} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {client && (
                                        <ClientLogs
                                            clientId={clientId}
                                            client={client}
                                            onClose={onClose}
                                            isEdit={isEdit}
                                            booking={booking}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </>
            </DialogContent>

            <DialogActions>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent={
                        isEdit ? 'space-between' : !isEdit && isHasAllowSendManualConfirm ? 'space-between' : 'flex-end'
                    }
                    direction={'row'}
                    wrap="nowrap"
                >
                    {!isEdit ? (
                        <>
                            {!isEdit && isHasAllowSendManualConfirm && (
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Checkbox
                                                size="small"
                                                style={{
                                                    padding: 0
                                                }}
                                                color="primary"
                                                isBorder={true}
                                                checked={includedSendConfirmation}
                                                onChange={(e) => setIncludedSendConfirmation(e.target.checked)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">Send confirmation text message</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item className={classes.wrapper}>
                                <StyledButton
                                    disabled={isSubmitting || !isValid}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                >
                                    {t(`create`)}
                                </StyledButton>

                                {isSubmitting && <CircularProgress size={12} className={classes.buttonProgress} />}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item>
                                <Grid container direction="row" spacing={1}>
                                    {isNoShow ? (
                                        <Grid item>
                                            <StyledButton
                                                onClick={handleNoShowBooking}
                                                startIcon={<EditIcon />}
                                                variant="outlined"
                                            >
                                                {t(`undo_no_show`)}
                                            </StyledButton>
                                        </Grid>
                                    ) : (
                                        <>
                                            {!isInternalBasic && (
                                                <Grid item className={classes.wrapper}>
                                                    <StyledButton
                                                        disabled={isConfirmArrived}
                                                        onClick={handleConfirmArrived}
                                                        startIcon={<ArrivedIcon />}
                                                    >
                                                        {t(`confirm_arrived`)}
                                                    </StyledButton>
                                                    {isConfirmArrived && (
                                                        <CircularProgress
                                                            size={12}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )}
                                                </Grid>
                                            )}

                                            {!isInternalBasic && (
                                                <Grid item>
                                                    <StyledButton
                                                        onClick={(e) => onReSchedule && onReSchedule(e, booking)}
                                                        startIcon={<RescheduleIcon />}
                                                    >
                                                        Re-Schedule
                                                    </StyledButton>
                                                </Grid>
                                            )}

                                            {!isInternalBasic && (
                                                <Grid item>
                                                    <StyledButton
                                                        onClick={(e) => onReBooking(e, booking)}
                                                        startIcon={<RebookIcon />}
                                                    >
                                                        Re-Book
                                                    </StyledButton>
                                                </Grid>
                                            )}

                                            <Grid item>
                                                <StyledButton
                                                    color="secondary"
                                                    onClick={handleCancelBooking}
                                                    startIcon={<CancelIcon />}
                                                >
                                                    {t(`cancel`)}
                                                </StyledButton>
                                            </Grid>

                                            {!isInternalBasic && (
                                                <Grid item className={classes.wrapper}>
                                                    <StyledButton
                                                        color="secondary"
                                                        onClick={handleNoShowBooking}
                                                        startIcon={<NoShowIcon />}
                                                        disabled={isConfirmNoShow}
                                                    >
                                                        {t(`no_show`)}
                                                    </StyledButton>

                                                    {isConfirmNoShow && (
                                                        <CircularProgress
                                                            size={12}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )}
                                                </Grid>
                                            )}

                                            {isInternalBasic && (
                                                <Grid item>
                                                    <StyledButton
                                                        onClick={(e) => handlePrintBooking(e, booking)}
                                                        startIcon={<PrintIcon />}
                                                    >
                                                        {t(`print`)}
                                                    </StyledButton>
                                                </Grid>
                                            )}

                                            {(isInternalBasic || isHasReviewButNoInternal || isInternal) && (
                                                <Grid item>
                                                    <StyledButton
                                                        onClick={(e) => handleMakeBookingDone(e, booking)}
                                                        startIcon={<DoneIcon />}
                                                    >
                                                        {t(`done`)}
                                                    </StyledButton>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item className={classes.wrapper}>
                                <StyledButton
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    disabled={isSubmitting || !isValid}
                                    variant="contained"
                                    type="submit"
                                >
                                    {t(`save`)}
                                </StyledButton>
                                {isSubmitting && <CircularProgress size={12} className={classes.buttonProgress} />}
                            </Grid>
                        </>
                    )}
                </Grid>
                <ConfirmDialog
                    open={isShowConfirmCancel}
                    title={`Cancel booking`}
                    text={`Do you want to cancel?`}
                    content={
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="body2">Do you want to cancel?</Typography>
                            </Grid>
                            {isShowRefund && (
                                <Grid item>
                                    <Grid container direction="row" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Checkbox
                                                size="small"
                                                style={{
                                                    padding: 0
                                                }}
                                                color="primary"
                                                isBorder={true}
                                                checked={includedRefund}
                                                onChange={(e) => setIncludedRefund(e.target.checked)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">Refund for this booking</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    }
                    onAgree={handleCancelAgree}
                    agreeLabel={`Agree`}
                    onDisagree={() => setIsShowConfirmCancel(false)}
                    type={CONFIRM_TYPES.ERROR}
                    isLoading={isDeleting}
                />

                <ConfirmDialog
                    open={isShowConfirmRefund}
                    title={`Refund booking`}
                    text={`Do you want to refund?`}
                    onAgree={handleRefundAgree}
                    agreeLabel={`Agree`}
                    onDisagree={() => setIsShowConfirmRefund(false)}
                    type={CONFIRM_TYPES.ERROR}
                    isLoading={isRefunding}
                />
            </DialogActions>
            {!!clientId && <FetchingData clientId={clientId} bookingId={bookingId} />}
        </form>
    );
}

export default React.memo(AddBooking);
