import React from 'react';

function NoPreferenceSVG({ color = '#FFF', ...rest }) {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...rest}>
            <path
                d="M13.5 2.669A5 5 0 0121 7v2.002a4.982 4.982 0 01-2 3.98v.338l3.742 1.5A2 2 0 0124 16.677V21a1 1 0 01-1 1h-4a1 1 0 010-2h3v-3.322l-3.742-1.5A2 2 0 0117 13.323v-.878a1 1 0 01.502-.867A2.981 2.981 0 0019 9V7A3 3 0 0014.5 4.4a1 1 0 11-1-1.732zM8 3a5 5 0 015 5c0 .64.307 1.407.832 2.195.195.293.405.566.615.81l.132.15.128.138a1 1 0 01.184 1.16c-.213.42-.696.941-1.53 1.415a7.55 7.55 0 01-1.743.7l3.125 1.253.147.066a2 2 0 011.11 1.79V21a1 1 0 01-1 1H1a1 1 0 01-1-1v-3.326a2 2 0 011.257-1.851l3.128-1.254a7.553 7.553 0 01-1.746-.701c-.834-.474-1.317-.995-1.53-1.415a1 1 0 01.184-1.16l.26-.288c.21-.244.42-.517.615-.81C2.693 9.407 3 8.641 3 8a5 5 0 015-5zm0 2a3 3 0 00-3 3l-.008.256c-.062 1.024-.491 2.046-1.16 3.049-.16.24-.32.46-.474.656.081.056.17.112.269.168.602.342 1.425.607 2.505.752a1 1 0 01.868.991v.452a2 2 0 01-1.257 1.855L2 17.68V20h12v-2.322l-3.742-1.5A2 2 0 019 14.323v-.451a1 1 0 01.868-.991c1.08-.145 1.903-.41 2.505-.752.098-.056.188-.112.269-.168a10.822 10.822 0 01-.474-.656C11.443 10.218 11 9.109 11 8l-.005-.176A3 3 0 008 5z"
                fillRule="evenodd"
            ></path>
        </svg>
    );
}

export default React.memo(NoPreferenceSVG);
