import * as types from './types';
import {
    getHidePriceDatesApi,
    createHidePriceDateApi,
    updateHidePriceDateApi,
    deleteHidePriceDateApi
} from 'services/hidePriceDate';
import { enqueueSnackbar } from 'notifier/actions';

export function fetchHidePriceDates({ errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_fetchHidePriceDatesAction());

        try {
            const response = await getHidePriceDatesApi();
            dispatch(_fetchHidePriceDatesActionSuccess({ hidePriceDates: response?.items || [] }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[fetchClosedDates] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_fetchHidePriceDatesActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _fetchHidePriceDatesAction() {
    return {
        type: types.FETCH_HIDE_PRICE_DATE
    };
}

export function _fetchHidePriceDatesActionSuccess({ hidePriceDates }) {
    return {
        type: types.FETCH_HIDE_PRICE_DATE_SUCCESS,
        payload: {
            hidePriceDates
        }
    };
}

function _fetchHidePriceDatesActionFailed() {
    return {
        type: types.FETCH_HIDE_PRICE_DATE_FAILED
    };
}

export function createHidePriceDate({ body, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_createHidePriceDateAction());
        try {
            const createdHidePriceDate = await createHidePriceDateApi({ body });
            dispatch(_createHidePriceDateActionSuccess({ hidePriceDate: createdHidePriceDate }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[createHidePriceDate] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_createHidePriceDateActionFailed());
            errorCallback && errorCallback(message);
        }
    };
}

function _createHidePriceDateAction() {
    return {
        type: types.ADD_HIDE_PRICE_DATE
    };
}

function _createHidePriceDateActionSuccess({ hidePriceDate }) {
    return {
        type: types.ADD_HIDE_PRICE_DATE_SUCCESS,
        payload: {
            hidePriceDate
        }
    };
}

function _createHidePriceDateActionFailed() {
    return {
        type: types.ADD_HIDE_PRICE_DATE_FAILED
    };
}

export function deleteHidePriceDate({ id, errorCallback, successCallback }) {
    return async function (dispatch) {
        dispatch(_deleteHidePricedDatesAction({ id }));
        try {
            await deleteHidePriceDateApi({ id });
            dispatch(_deleteHidePricedDatesActionSuccess({ id }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[deleteHidePriceDate] ${message}`,
                    type: 'info'
                })
            );
            dispatch(_deleteHidePricedDatesActionFailed({ id }));
            errorCallback && errorCallback(message);
        }
    };
}

function _deleteHidePricedDatesAction({ id }) {
    return {
        type: types.DELETE_HIDE_PRICE_DATE,
        payload: {
            id
        }
    };
}

function _deleteHidePricedDatesActionSuccess({ id }) {
    return {
        type: types.DELETE_HIDE_PRICE_DATE_SUCCESS,
        payload: {
            id
        }
    };
}

function _deleteHidePricedDatesActionFailed({ id }) {
    return {
        type: types.DELETE_HIDE_PRICE_DATE_FAILED,
        payload: {
            id
        }
    };
}

export function updateHidePriceDate({ id, body, errorCallback, successCallback }) {
    return async function (dispatch) {
        try {
            const updatedHidePriceDate = await updateHidePriceDateApi({ id, body });
            dispatch(_updateHidePriceDatesActionSuccess({ id, hidePriceDate: updatedHidePriceDate }));
            successCallback && successCallback();
        } catch (error) {
            const { message } = error;
            dispatch(
                enqueueSnackbar({
                    message: `[updateHidePriceDate] ${message}`,
                    type: 'info'
                })
            );
            errorCallback && errorCallback(message);
        }
    };
}

function _updateHidePriceDatesActionSuccess({ id, hidePriceDate }) {
    return {
        type: types.UPDATE_HIDE_PRICE_DATE_SUCCESS,
        payload: {
            id,
            hidePriceDate
        }
    };
}
