import React from 'react';

function StaffNotFoundSVG({ color = '#FFF', ...rest }) {
    return (
        <svg {...rest} width="100%" height="100%" viewBox="0 0 533 370" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M410 31C413.866 31 417 27.866 417 24C417 20.134 413.866 17 410 17C406.134 17 403 20.134 403 24C403 27.866 406.134 31 410 31Z"
                fill="#F1F1F1"
            />
            <path
                d="M369 300C372.866 300 376 296.866 376 293C376 289.134 372.866 286 369 286C365.134 286 362 289.134 362 293C362 296.866 365.134 300 369 300Z"
                fill="#F1F1F1"
            />
            <path
                d="M197 97C200.866 97 204 93.866 204 90C204 86.134 200.866 83 197 83C193.134 83 190 86.134 190 90C190 93.866 193.134 97 197 97Z"
                fill="#F1F1F1"
            />
            <path
                d="M171.07 282L190 276.174C188.952 268.44 186.561 260.939 182.93 254L164 259.826C168.442 266.413 170.894 274.101 171.07 282Z"
                fill="#5E81F4"
            />
            <path
                d="M126 143C135.389 143 143 135.165 143 125.5C143 115.835 135.389 108 126 108C116.611 108 109 115.835 109 125.5C109 135.165 116.611 143 126 143Z"
                fill="#2F2E41"
            />
            <path d="M107.208 343L104 358H111.792L115 341L107.208 343Z" fill="#9F616A" />
            <path d="M146 344V357.067L154 358V344H146Z" fill="#9F616A" />
            <path
                d="M105 193.461C105 193.461 105.909 213.343 106.818 214.731C107.727 216.118 115.909 236 115.909 236L120 223.053C120 223.053 113.636 212.419 114.545 206.408C115.455 200.397 114.882 193 114.882 193L105 193.461Z"
                fill="#9F616A"
            />
            <path
                d="M132.5 145C139.956 145 146 138.956 146 131.5C146 124.044 139.956 118 132.5 118C125.044 118 119 124.044 119 131.5C119 138.956 125.044 145 132.5 145Z"
                fill="#9F616A"
            />
            <path
                d="M121.132 133C121.132 133 121.599 151.793 118.332 154.613C115.065 157.432 137 155.082 137 155.082C137 155.082 132.333 141.927 136.067 139.108C139.8 136.289 121.132 133 121.132 133Z"
                fill="#9F616A"
            />
            <path
                d="M138.619 154.74C138.619 154.74 135.774 149.559 131.982 150.03C128.189 150.501 115.862 151.443 115.388 152.385C114.914 153.327 113.491 156.623 112.543 157.565C111.595 158.507 104.484 159.92 104.009 165.571C103.535 171.222 121.077 205.599 121.077 205.599C121.077 205.599 118.707 213.605 119.181 215.489C119.655 217.372 116.81 223.024 117.284 225.378C117.758 227.733 110.173 241.86 113.966 260.227V289.895C113.966 289.895 101.165 341.226 104.958 344.993C108.75 348.76 121.551 346.406 123.922 344.993C126.292 343.58 136.248 272.471 136.248 272.471L140.989 248.453L141.464 301.197C141.464 301.197 140.515 345.464 143.834 346.877C147.153 348.289 159.954 348.76 160.902 345.935C161.641 343.731 165.265 285.097 166.824 259.395C167.364 250.523 166.661 241.62 164.736 232.94C162.763 224.05 159.898 213.606 156.635 209.367C150.472 201.361 146.679 175.931 146.679 175.931L154.738 161.332C154.738 161.332 149.997 156.623 147.153 156.623C144.308 156.623 138.619 154.74 138.619 154.74Z"
                fill="#2F2E41"
            />
            <path
                d="M107.493 161L104.923 162.693C104.923 162.693 103.107 193.065 104.569 194.951C106.031 196.837 116.193 195.917 116.959 194.255C117.725 192.594 107.493 161 107.493 161Z"
                fill="#2F2E41"
            />
            <path
                d="M146 188.966C146 188.966 146.469 208.705 149.283 214.345C152.097 219.985 162.416 243.484 162.416 243.484C162.416 243.484 161.817 269.348 167.041 262.996C172.265 256.644 168.982 241.604 168.982 241.604L160.07 206.825C160.07 206.825 155.38 191.315 155.849 186.616C156.318 181.916 146 188.966 146 188.966Z"
                fill="#9F616A"
            />
            <path
                d="M154.42 349.043C154.42 349.043 154.887 348.685 156.288 350.031C157.69 351.376 161.427 353.171 160.96 358.105C160.493 363.039 157.69 363.936 157.69 363.936V362.591H156.288V364.834C156.288 364.834 153.952 372.011 143.208 367.525C143.208 367.525 142.74 363.936 143.208 361.694C143.675 359.451 143.675 348.685 145.076 349.133C146.478 349.582 150.215 350.031 151.149 351.825C152.084 353.619 154.887 352.99 154.42 349.043Z"
                fill="#2F2E41"
            />
            <path
                d="M105.58 350.043C105.58 350.043 105.113 349.685 103.712 351.031C102.31 352.376 98.573 354.171 99.0401 359.105C99.5073 364.039 102.31 364.936 102.31 364.936V363.591H103.712V365.834C103.712 365.834 106.048 373.011 116.792 368.525C116.792 368.525 117.26 364.936 116.792 362.694C116.325 360.451 116.325 349.685 114.924 350.133C113.522 350.582 109.785 351.031 108.851 352.825C107.916 354.619 105.113 353.99 105.58 350.043Z"
                fill="#2F2E41"
            />
            <path
                d="M147.466 160L154.032 161.41C154.032 161.41 154.032 171.752 155.907 175.982C157.783 180.213 161.066 190.084 159.659 190.554C158.252 191.024 145.122 197.605 144.184 193.844C143.246 190.084 140.901 160 147.466 160Z"
                fill="#2F2E41"
            />
            <path
                d="M107 120C116.389 120 124 112.165 124 102.5C124 92.835 116.389 85 107 85C97.6112 85 90 92.835 90 102.5C90 112.165 97.6112 120 107 120Z"
                fill="#2F2E41"
            />
            <path
                d="M92.6464 117.006C97.1283 120.254 102.902 121.124 108.138 119.339C113.373 117.553 117.422 113.334 119 108.019C118.198 114.419 113.875 119.824 107.819 121.998C101.763 124.172 95.0024 122.747 90.332 118.311C85.6616 113.876 83.8747 107.183 85.7099 101C84.9312 107.206 87.591 113.343 92.6464 117.006Z"
                fill="#2F2E41"
            />
            <path
                d="M122 142C129.18 142 135 136.18 135 129C135 121.82 129.18 116 122 116C114.82 116 109 121.82 109 129C109 136.18 114.82 142 122 142Z"
                fill="#2F2E41"
            />
            <path
                d="M132.5 133C138.851 133 144 128.971 144 124C144 119.029 138.851 115 132.5 115C126.149 115 121 119.029 121 124C121 128.971 126.149 133 132.5 133Z"
                fill="#2F2E41"
            />
            <path
                d="M135 137C136.105 137 137 135.209 137 133C137 130.791 136.105 129 135 129C133.895 129 133 130.791 133 133C133 135.209 133.895 137 135 137Z"
                fill="#A0616A"
            />
            <path d="M249.309 10.2272L490.18 82.7286L432.072 276.695L191.201 204.193L249.309 10.2272Z" fill="#F1F1F1" />
            <path d="M346 66L403 89" stroke="#3F3D56" strokeWidth="2" />
            <path
                d="M347.132 72L347.892 71.5532L346.141 66.8418L350 64.986L349.7 64L345 66.2615L347.132 72Z"
                fill="#3F3D56"
            />
            <path
                d="M399.3 93L399 92.014L402.859 90.1587L401.108 85.4468L401.868 85L404 90.7395L399.3 93Z"
                fill="#3F3D56"
            />
            <path
                d="M375 83C377.761 83 380 80.7614 380 78C380 75.2386 377.761 73 375 73C372.239 73 370 75.2386 370 78C370 80.7614 372.239 83 375 83Z"
                fill="#3F3D56"
            />
            <path d="M342 228L394 196" stroke="#3F3D56" strokeWidth="2" />
            <path
                d="M346.834 229L347 228.158L343.03 227.309L344.131 223.233L343.339 223L342 227.965L346.834 229Z"
                fill="#3F3D56"
            />
            <path
                d="M393.66 199L392.869 198.767L393.969 194.692L390 193.842L390.166 193L395 194.036L393.66 199Z"
                fill="#3F3D56"
            />
            <path
                d="M368 216C370.761 216 373 213.761 373 211C373 208.239 370.761 206 368 206C365.239 206 363 208.239 363 211C363 213.761 365.239 216 368 216Z"
                fill="#3F3D56"
            />
            <path d="M274.991 125L275.009 186" stroke="#3F3D56" strokeWidth="2" />
            <path
                d="M271 128.329L271.717 129L274.999 125.445L278.284 128.998L279 128.326L274.998 124L271 128.329Z"
                fill="#3F3D56"
            />
            <path
                d="M271 183.674L271.716 183.002L275 186.555L278.284 183L279 183.671L275.001 188L271 183.674Z"
                fill="#3F3D56"
            />
            <path
                d="M275 161C277.761 161 280 158.761 280 156C280 153.239 277.761 151 275 151C272.239 151 270 153.239 270 156C270 158.761 272.239 161 275 161Z"
                fill="#3F3D56"
            />
            <rect x="231" width="86" height="106" fill="#3F3D56" />
            <path
                d="M268 40H248.974C247.675 32.3898 247.675 24.6102 248.974 17H268C265.857 24.5127 265.857 32.4873 268 40Z"
                fill="#FF6584"
            />
            <path
                d="M280 51H299.026C300.325 43.3898 300.325 35.6102 299.026 28H280C282.143 35.5127 282.143 43.4873 280 51Z"
                fill="#5E81F4"
            />
            <path
                d="M261 89H280.026C281.325 81.3898 281.325 73.6102 280.026 66H261C263.143 73.5127 263.143 81.4873 261 89Z"
                fill="#5E81F4"
            />
            <rect x="410" y="102" width="86" height="106" fill="#3F3D56" />
            <rect x="422" y="124" width="61" height="3" fill="#5E81F4" />
            <rect x="422" y="134" width="61" height="3" fill="#5E81F4" />
            <rect x="422" y="144" width="61" height="3" fill="#5E81F4" />
            <rect x="422" y="153" width="61" height="3" fill="#5E81F4" />
            <rect x="422" y="163" width="61" height="3" fill="#5E81F4" />
            <rect x="437" y="174" width="32" height="12" fill="#5E81F4" />
            <rect x="239" y="211" width="86" height="106" fill="#3F3D56" />
            <rect x="252" y="271" width="61" height="3" fill="#5E81F4" />
            <rect x="252" y="280" width="61" height="3" fill="#5E81F4" />
            <rect x="252" y="290" width="61" height="3" fill="#5E81F4" />
            <rect x="252" y="300" width="61" height="3" fill="#5E81F4" />
            <path
                opacity="0.3"
                d="M270 249H250.974C249.675 241.39 249.675 233.61 250.974 226H270C267.857 233.513 267.857 241.487 270 249Z"
                fill="#5E81F4"
            />
            <rect opacity="0.3" x="279" y="227" width="36" height="3" fill="#5E81F4" />
            <rect opacity="0.3" x="279" y="236" width="36" height="3" fill="#5E81F4" />
            <rect opacity="0.3" x="279" y="245" width="36" height="3" fill="#5E81F4" />
            <rect opacity="0.3" x="279" y="253" width="36" height="3" fill="#5E81F4" />
            <path
                opacity="0.1"
                d="M518.124 336.567C506.339 346.341 489.569 350.385 473.351 352.94L470.621 353.356C453.682 355.882 436.554 357.227 419.367 358.044C410.172 358.49 400.976 358.804 391.781 359.085L384.574 359.298C375.217 359.575 365.842 359.847 356.45 360.115C347.254 360.376 338.059 360.622 328.863 360.851C317.086 361.138 305.309 361.376 293.533 361.567C284.337 361.712 275.141 361.812 265.946 361.867C254.166 361.934 242.389 361.912 230.615 361.799C221.413 361.705 212.217 361.544 203.028 361.315C191.245 361.012 179.468 360.568 167.698 359.985C159.709 359.594 151.725 359.125 143.746 358.577L140.111 358.32C105.86 355.853 70.9937 351.426 41.0208 337.434L40.2125 337.051C39.8108 336.863 39.4091 336.674 39.0074 336.476C33.5042 333.845 28.2327 330.756 23.249 327.239C14.3874 320.921 7.06958 313.208 3.20258 304.059C-2.85685 289.709 0.347095 273.578 7.26801 259.547C14.1889 245.517 24.58 232.85 33.5675 219.637C36.2003 215.766 38.6977 211.751 41.0208 207.633C55.5402 181.811 62.7418 151.93 47.5206 127.047C45.5115 123.875 43.3421 120.807 41.0208 117.855C37.3087 113.017 33.5336 108.178 30.983 102.822C25.0349 90.359 26.3126 76.3427 28.4809 63.0278C31.3847 45.3247 36.7085 26.2572 53.3478 15.197C70.6113 3.71098 95.1152 4.29157 116.541 8.42343C149.853 14.8051 180.601 28.1635 211.638 40.2107C242.676 52.2579 275.19 63.2746 309.315 64.4599C316.646 64.7107 323.985 64.444 331.278 63.6616C341.001 62.6628 350.591 60.6438 359.891 57.638C373.322 53.2159 385.542 46.5633 394.602 37.3174C414.034 17.5532 450.55 16.0582 476.879 27.9941C502.002 39.3833 518.249 63.4874 517.049 87.5625C515.684 114.362 495.609 136.395 477.213 158.994C474.982 161.733 472.779 164.481 470.635 167.248C469.438 168.793 468.264 170.342 467.112 171.893C465.094 174.632 463.143 177.384 461.304 180.171C451.905 194.459 445.26 211.62 451.731 227.044C455.361 235.68 462.287 242.27 470.635 248.023C489.341 260.907 515.162 269.577 526.777 287.614C536.786 303.159 533.079 324.143 518.124 336.567Z"
                fill="#5E81F4"
            />
        </svg>
    );
}

export default React.memo(StaffNotFoundSVG);
